import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`English Language Proficiency is preferred but not required for admission between Early Childhood 3 and Grade 5. Applicants may be tested to assist in programme development and placement.`}</p>
    <p>{`In the event there are more Ukrainian applicants than spaces, testing and interviews will assist in making the admission decision. The testing will be similar to the post-admission testing listed below.`}</p>
    <p>{`Children of all nationalities may seek admission to the school. Prospective applicants will be considered unless there is reason to believe that admission is not in the best interest of the applicant or the school. Students with mild learning support needs will be accommodated with the expectation that the student will be mainstreamed.`}</p>
    <h2 {...{
      "id": "admission-interview-and-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#admission-interview-and-assessment",
        "aria-label": "admission interview and assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Admission Interview and Assessment`}</h2>
    <ol>
      <li parentName="ol">{`All students for whom English is not a native language may receive the WIDA assessment at the start of the school year. This will allow the school to know if focused EAL instruction will be necessary.`}</li>
    </ol>
    <p>{`2`}{`.`}{` All ‘face to face’ applications will undergo the following assessments:`}</p>
    <blockquote>
      <p parentName="blockquote">{`a`}{`.`}{` EC - Kindergarten student applicants who are in Kyiv are invited to a playdate on campus where they are observed by our PSI Early Childhood programme teachers and teaching assistants, counsellor, learning support specialist, and principal.`}</p>
    </blockquote>
    <p>{`3`}{`.`}{` In cases where concerns are raised regarding if PSI can meet a students’ needs, admission will be on hold pending further investigation. In such cases, admissions decisions will be made in consultation with the Learning Support Coordinator. In some cases, psychoeducational testing with its recommendations and programme outline will be required to determine whether PSI can meet the child’s needs. Similarly, trial days can be used to further clarify whether PSI can meet the needs of the student.`}</p>
    <p>{`4`}{`.`}{` When we do not meet families ahead of enrolment, all documents required by PSI must be received`}</p>
    <p>{`before an enrolment is made. This current process includes:`}</p>
    <p>{`-`}{` School report cards`}</p>
    <p>{`-`}{` Two confidential reference forms from the child’s current teacher and principal or counsellor`}</p>
    <p>{`-`}{` Contact with school counsellors / teacher / case manager to clarify issues that arise`}</p>
    <p>{`5`}{`.`}{` This process will be adhered to regardless of an application coming from siblings.`}</p>
    <h2 {...{
      "id": "admissions-for-students-with-learning-support-needs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#admissions-for-students-with-learning-support-needs",
        "aria-label": "admissions for students with learning support needs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Admissions for Students with Learning Support Needs`}</h2>
    <p>{`PSI admissions states that we can accept students with mild to moderate learning challenges. In the event an admission is flagged as having possible or definite Learning Support needs, more thorough admissions procedures will be needed. This will ensure that PSI can meet the student’s needs and that the school can be prepared as possible to deliver the appropriate level of support when and if the student attends.`}</p>
    <p><strong parentName="p">{`In the case that a family or school notifies PSI of a potential student’s learning support needs during the application process PSI will:`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Contact the family directly to explain the level of service offered at PSI and our programme structure`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ask to review previous report cards, assessments / official testing, and any previous Learning Support Plans`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ask for contact information of the child’s previous case manager, teacher and / or counsellor and communicate with them either via email or video conference with the family’s permission`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`In the case that a potential student is flagged by PSI during the admissions process as having learning support needs, during the application process PSI will:`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Involve the Learning Support Teacher and Counsellor in the admissions process`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Discuss concerns with the family`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ask to review previous report cards, assessments / official testing, and any previous Learning Support Plans`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ask for contact information of the child’s previous case manager, teacher and counsellor and communicate with them either via email or Skype with the families permission`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Possibly administer additional assessments such as:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`WIDA (for non native English speakers)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fountas & Pinnell or DRA2 running record assessment (for native or strong English speakers)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Math Inventory (for Grades 1 - 5)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Writing Sample on a topic of the student’s choice.`}</p>
      </li>
    </ul>
    <p>{`Once all assessment data is gathered, the Primary principal will meet with the Learning Support teacher and guidance counsellor to analyse the information and determine the best placement for the child. If there continues to be a high level of concern of whether or not PSI can meet the child’s needs, there will be a free 2-week trial period to ensure the correct decision has been made.`}</p>
    <h2 {...{
      "id": "primary-school-applicant-admissions-procedures-and-responsibilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#primary-school-applicant-admissions-procedures-and-responsibilities",
        "aria-label": "primary school applicant admissions procedures and responsibilities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Primary School Applicant Admissions Procedures and Responsibilities`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Task`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Person Responsible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timeline / National (UA)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timeline / Expats`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Complete admissions packet is shared with the Primary principal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Admissions Manager`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ongoing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ongoing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Principal reviews complete admissions package`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary principal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 days within receiving application`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 days within receiving application`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning support concerns are shared with the LS teacher who reports back to the principal if further research is needed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary principal and Learning Support teacher`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 week within receiving application`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 week within receiving application`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Testing of non-native English speakers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EAL teacher`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mid-May or start of academic year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May or start of academic year`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Testing of face-to-face applicants (if children are in Kyiv or nearby)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary principal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mid-May or start of academic year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May, summer and ongoing as applications are received throughout the year`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Admissions interviews with parents and students`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary principal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`May`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ongoing`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      